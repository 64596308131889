export default function FileCard({ file, viewFileURL, removeFile, fileType }) {
  const formatFileSize = (sizeInBytes) => {
    return sizeInBytes < 1024 * 1024
      ? `${(sizeInBytes / 1024).toFixed(0)} KB`
      : `${(sizeInBytes / (1024 * 1024)).toFixed(1)} MB`;
  };

  const fileIcon = (type) => {
    switch (type) {
      case "image":
        return "/icon_image.png";
      default:
        return "/icon_document.png";
    }
  };

  return (
    <div className="w-full flex justify-between items-start p-4 gap-3 border border-gray-300 rounded-lg">
      <img src={fileIcon(fileType)} alt="File Icon" />
      <div className="w-full flex flex-col">
        <p
          className="text-left font-medium text-sm text-black35"
          style={{ lineHeight: "20px" }}
        >
          <span className="break-all">{file?.name}</span>
          <br />
          <span className="text-xs text-gray-500">
            {formatFileSize(file?.size)}
          </span>
        </p>
        <a
          href={viewFileURL}
          target="_blank"
          rel="noopener noreferrer"
          className="text-left font-semibold text-sm text-primaryColor"
          style={{ lineHeight: "20px" }}
        >
          Lihat isi file
        </a>
      </div>
      {removeFile && (
        <button type="button" onClick={removeFile}>
          <img src="/icon_trashcan_red.png" alt="Remove file" />
        </button>
      )}
    </div>
  );
}
