import { useEffect, useState } from "react";
import ButtonV2 from "../../components/ButtonV2";
import LayoutV2 from "../../components/Layout_V2";
import WelcomePage from "./pages/WelcomePage";
import PhotoPage from "./pages/PhotoPage";
import PassportPage from "./pages/PassportPage";
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import { studentFormDataState } from "../../atoms/studentFormDataState";
import OtherPage from "./pages/OtherPage";
import NotePage from "./pages/NotePage";
import ReviewPage from "./pages/ReviewPage";
import ResultPage from "./pages/ResultPage";
import { authState } from "../../atoms/authState";
import { api, notifError } from "../../utils/api";

export default function StudentDataForm() {
  const params = new URLSearchParams(window.location.search);
  const auth = useRecoilValue(authState);
  const studentData = useRecoilValue(studentFormDataState);
  const clearStudentData = useResetRecoilState(studentFormDataState);
  const setStudentData = useSetRecoilState(studentFormDataState);
  const [currentPage, setCurrentPage] = useState(0);
  const [isCTADisable, setIsCTADisable] = useState(false);

  useEffect(() => {
    if (currentPage === 0) {
      clearStudentData();
    }
  }, [currentPage]);

  useEffect(() => {
    setStudentData((prevData) => ({
      ...prevData,
      studentName: `${auth.fname} ${auth.lname}`,
    }));
  }, [auth]);

  function isFormValid(currentPage, studentData) {
    switch (pages[currentPage].id) {
      case "photo":
        return !!studentData.photo;
      case "paspor":
        return !!studentData.passportNumber && !!studentData.passportFile;
      case "other":
        return (
          !!studentData.address &&
          !!studentData.allergies &&
          !!studentData.medicalCondition &&
          !!studentData.shirtSize
        );
      case "note":
        return !!studentData.notes;
      default:
        return true;
    }
  }

  useEffect(() => {
    setIsCTADisable(!isFormValid(currentPage, studentData));
  }, [studentData, currentPage]);

  function prevPage() {
    setCurrentPage(currentPage - 1);
  }

  function nextPage() {
    setCurrentPage(currentPage + 1);
  }

  const pages = [
    {
      id: "welcome",
      title: "Form Pelengkapan Data Peserta",
      page: <WelcomePage topic={params.get("tp")} />,
      buttonTitle: "Mulai",
    },
    {
      id: "photo",
      title: "Foto Peserta",
      page: (
        <PhotoPage
          data={studentData}
          ctaDisableToggle={(e) => {
            setIsCTADisable(e);
          }}
        />
      ),
      buttonTitle: "Lanjut",
      isPrevButton: true,
      prevButtonTitle: "Sebelumnya",
    },
    {
      id: "paspor",
      title: "Paspor Peserta",
      page: <PassportPage data={studentData} />,
      buttonTitle: "Lanjut",
      isPrevButton: true,
      prevButtonTitle: "Sebelumnya",
    },
    {
      id: "other",
      title: "Informasi Lainnya",
      page: <OtherPage data={studentData} />,
      buttonTitle: "Lanjut",
      isPrevButton: true,
      prevButtonTitle: "Sebelumnya",
    },
    {
      id: "note",
      title: "Notes Tambahan",
      page: <NotePage data={studentData} />,
      buttonTitle: "Lanjut",
      isPrevButton: true,
      prevButtonTitle: "Sebelumnya",
    },
    {
      id: "review",
      title: "Review Form Pelengkapan Data",
      page: <ReviewPage data={studentData} editData={editData} />,
      buttonTitle: "Submit",
      isPrevButton: false,
    },
    {
      id: "result",
      title: "Anda telah submit Form Pelengkapan Data",
      page: <ResultPage data={studentData} />,
    },
  ];

  function editData(pageIndex) {
    setCurrentPage(pageIndex);
  }

  function onSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    setIsCTADisable(true);
    if (currentPage < 5) {
      nextPage();
    } else {
      const jsonBody = {
        paspor_no: studentData.passportNumber,
        paspor_expired_at: studentData.passportExpiredDate,
        address: studentData.address,
        diet_allergy_requirements: studentData.allergies,
        medical_condition: studentData.medicalCondition,
        clothes_size: studentData.shirtSize,
        additional_notes: studentData.notes,
      };

      const formData = new FormData();
      formData.append("pas_photo", studentData.photo);
      formData.append("paspor_file", studentData.passportFile);
      formData.append("form_code", params.get("fc"));
      formData.append("json_string", JSON.stringify(jsonBody));

      api
        .post("/program/participant/form/submit", formData)
        .then((_) => {
          window.Swal.fire({
            text: "Data berhasil di-submit!",
            icon: "success",
            confirmButtonText: "Tutup",
          }).then(() => {
            nextPage();
            setIsCTADisable(false);
          });
        })
        .catch(notifError);
    }
  }

  return (
    <LayoutV2 padding="0px 0px 45px 0px">
      <img
        src={
          window.innerWidth < 1024
            ? "/banner_top_mobile.png"
            : "/banner_top_desktop.svg"
        }
        className="w-full h-auto"
        style={{ minHeight: window.innerWidth >= 1024 && "59px" }}
      />
      <form
        onSubmit={onSubmit}
        className="w-full flex flex-col justify-start items-center gap-5"
        style={{
          padding:
            window.innerWidth < 1024
              ? "22px 20px 0px 21px"
              : "34px 71.22px 0px 71px",
        }}
      >
        {currentPage < 5 && window.innerWidth >= 1024 && (
          <div
            className="w-full flex justify-between items-center pb-5"
            style={{
              borderBottom: "0.89px solid #2410364D",
            }}
          >
            {pages.slice(1, 5).map((item, index) => (
              <div
                key={index}
                className="flex justify-center items-center gap-3"
                style={{
                  minWidth: index < 3 ? "302.48px" : "auto",
                  maxWidth: index < 3 ? "302.48px" : "auto",
                }}
              >
                <div
                  className="w-11 h-11 flex justify-center items-center rounded-full"
                  style={{
                    backgroundColor:
                      currentPage === index + 1 ? "#FFCF2D" : "#BDB7C3",
                    aspectRatio: "1",
                  }}
                >
                  <p
                    className={`text-center font-bold text-base ${
                      currentPage === index + 1 ? "text-black" : "text-white"
                    }`}
                    style={{ lineHeight: "20px" }}
                  >{`0${index + 1}`}</p>
                </div>
                <p
                  className={`text-center font-semibold text-base text-black whitespace-nowrap`}
                  style={{ lineHeight: "20px" }}
                >
                  {item.title}
                </p>
                {index < 3 && (
                  <div
                    className="w-full flex flex-1"
                    style={{
                      backgroundColor: "#D9D9D9",
                      minHeight: "4px",
                      maxHeight: "4px",
                    }}
                  />
                )}
              </div>
            ))}
          </div>
        )}
        {currentPage > 4 && window.innerWidth >= 1024 && (
          <div
            className="w-full pb-5"
            style={{
              paddingTop: "21px",
              borderBottom: "0.89px solid #2410364D",
            }}
          >
            <p
              className="text-left font-bold text-2xl text-black35"
              style={{ lineHeight: "20px" }}
            >
              {pages[currentPage].title}
            </p>
          </div>
        )}
        <div
          className="flex flex-col justify-start items-stretch gap-5"
          style={{
            minWidth: window.innerWidth < 1024 ? "100%" : "741px",
            maxWidth: window.innerWidth < 1024 ? "100%" : "741px",
          }}
        >
          <div
            className="w-full flex justify-start sm:justify-center items-center gap-5 pb-5"
            style={{
              borderBottom:
                window.innerWidth < 1024 && "0.89px solid #2410364D",
            }}
          >
            {currentPage > 0 && currentPage < 5 && window.innerWidth < 1024 && (
              <div
                className="w-11 h-11 rounded-full flex justify-center items-center"
                style={{ backgroundColor: "#FFCF2D" }}
              >
                <p
                  className="text-center font-bold text-base text-black"
                  style={{ lineHeight: "20px" }}
                >{`0${currentPage}`}</p>
              </div>
            )}
            {(currentPage === 0 || window.innerWidth < 1024) && (
              <p
                className="text-left tablet:text-center font-bold text-xl tablet:text-2xl text-black"
                style={{ lineHeight: window.innerWidth < 1024 && "25.2px" }}
              >
                {pages[currentPage].title}
                {currentPage === 0 && (
                  <>
                    <br />
                    <span className="text-primaryColor">
                      OCBC Summer Camp 2025
                    </span>
                  </>
                )}
              </p>
            )}
          </div>
          {pages[currentPage].page}
          <div className="w-full flex justify-between">
            {pages[currentPage].isPrevButton ? (
              <ButtonV2
                type="button"
                onClick={prevPage}
                title={pages[currentPage].prevButtonTitle}
                customStyle={{ maxWidth: "148.04px" }}
                secondary={true}
              />
            ) : (
              <div className="w-full flex-1" />
            )}
            {currentPage < 6 && (
              <ButtonV2
                disabled={currentPage === 4 ? false : isCTADisable}
                type="submit"
                title={pages[currentPage].buttonTitle}
                customStyle={{ maxWidth: "110.5px" }}
              />
            )}
          </div>
        </div>
      </form>
    </LayoutV2>
  );
}
