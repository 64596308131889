import { useEffect, useRef, useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/base/Button";
import Layout from "../components/Layout";
import { api, apiAnom, notifError, getAnomToken } from "../utils/api";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import Cookies from "js-cookie";
import PageLoading from "./PageLoading";
import { InputPassword } from "../components/InputPassword";
import Footer from "../components/Footer";
import Headerv2 from "../components/Header_v2";
import { signInUpState } from "../atoms/signInUpState";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import Carousel from "react-multi-carousel";
import SigninupSlide from "../components/SigninupSlide";
import ButtonV2 from "../components/ButtonV2";
import LayoutV2 from "../components/Layout_V2";
import Slider from "react-slick";
import FormField from "../components/FormField";
import charCardInfo from "./CharCardInfo.json";
import SignInCarouselSlides from "../components/SignInCarouselSlides";
import RegisterEmailCheck from "../components/RegisterEmailCheck";

export default function LoginV2({ login, register }) {
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const [receiveUpdate, setReceiveUpdate] = useState(false);
  const setAuth = useSetRecoilState(authState);
  const auth = useRecoilValue(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [agreeTNC, setAgreeTNC] = useState(false);
  const [agreeLegal, setAgreeLegal] = useState(false);
  const charCardsRef = useRef();
  const [usernameError, setUsernameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [formData, setFormData] = useState(null);

  const checkUsername = async (username) => {
    try {
      if (username.length > 30) {
        setUsernameError("Username harus mengandung maksimal 30 karakter");
        return false;
      }

      const usernameRegex = /^[a-z0-9._]{8,}$/;
      if (!usernameRegex.test(username)) {
        setUsernameError(
          "Username harus mengandung minimal 8 karakter dan hanya diperbolehkan menggunakan karakter (a-z), (0-9), (_), dan (.)"
        );
        return false;
      }
      // Pastikan token sudah ada
      if (!Cookies.get("anom.token")) {
        await getAnomToken();
      }

      const response = await apiAnom.get(
        `/auth/username/availability?username=${username}`
      );

      // Sesuaikan dengan struktur response API
      if (response.data.code === 200) {
        if (!response.data.data.availability) {
          setUsernameError(
            <span>
              Username sudah terdaftar, silahkan gunakan username lain atau{" "}
              <Link to="/signin" className="text-primaryColor hover:underline">
                masuk ke akunmu
              </Link>
            </span>
          );
          return false;
        }
        setUsernameError("");
        return true;
      }

      return false;
    } catch (error) {
      notifError(error);
      return false;
    }
  };

  const validateEmail = (email) => {
    // Basic email format validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    if (!emailRegex.test(email)) {
      setEmailError("Format email tidak valid");
      return false;
    }

    setEmailError("");
    return true;
  };

  const checkEmailExistence = async (email) => {
    try {
      const response = await apiAnom.post("/auth/check-email", { email });
      return response.data.data.is_registered;
    } catch (error) {
      notifError(error);
      return false;
    }
  };

  const handleEmailOTP = async (email, firstName, lastName, username) => {
    try {
      await apiAnom.post("/auth/email-otp/send", {
        email: email,
        first_name: firstName,
        last_name: lastName,
      });
      navigate("/validate-otp", {
        state: {
          email: email,
          username: username,
          first_name: firstName,
          last_name: lastName,
        },
      });
    } catch (error) {
      notifError(error);
    }
  };

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const onSubmit = async (e) => {
    const { elements } = e.target;
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);

    const firstName = e.target.elements[0].value;
    const lastName = e.target.elements[1].value;
    const email = e.target.elements[2].value;
    const username = e.target.elements[3].value;

    // Store form data
    setFormData({
      firstName,
      lastName,
      email,
      username,
    });

    const isUsernameAvailable = await checkUsername(username);
    if (!isUsernameAvailable) {
      setLoading(false);
      return;
    }

    let classSlug = "";

    if (window.location.pathname.split("/")[1] === "explore-kelas") {
      classSlug = window.location.pathname.split("/")[2];
    }

    const payload = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      username: username,
      password: elements[4].value,
      class_slug: classSlug,
    };

    apiAnom
      .post("/students/registration", payload)
      .then((res1) => {
        const tmb_result_id = res1.data.data.tmb_result_id;
        Cookies.set("user.token", res1.data.data.request_token, {
          sameSite: "None",
          secure: true,
          expires: 1,
        });

        api
          .get("/me", {
            headers: {
              Authorization: "Bearer " + res1.data.data.request_token,
            },
          })
          .then((res) => {
            setAuth({ ...res.data.data, isAuth: true });
            const params = new URLSearchParams(window.location.search);
            let path = "/profile";
            if (params.get("redirect")) {
              path = params.get("redirect");
            }
            if (
              res.data.data.phone === "" ||
              res.data.data.verified_at.split("-")[0] === "0001"
            ) {
              path = "/profile-edit";
            }
            if (tmb_result_id > 0) {
              navigate(`/tes-minat-result/${tmb_result_id}`);
            } else {
              navigate(path);
            }
          })
          .catch(notifError);
      })
      .catch((error) => {
        if (
          error.response?.data?.error?.message?.includes(
            "similar email address already exists"
          )
        ) {
          setUserEmail(email);
          setIsOpen(true);
        } else {
          notifError(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const charCardsSettings = {
    arrows: false,
    className: "center",
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
  };

  const charCards = charCardInfo.map((item, index) => (
    <SignInCarouselSlides
      key={index}
      index={index}
      charCode={item.charCode}
      backgroundColor={item.backgroundColor}
      iconUrl={
        window.innerWidth < 1024
          ? item.iconUrlMobile
          : window.location.pathname === "/signup"
          ? item.iconUrlSignup
          : item.iconUrlDesktop
      }
      customHeightMobile={
        window.location.pathname === "/signup" ? "1040px" : "916px"
      }
      customHeightDesktop={
        window.location.pathname === "/signup" ? "758px" : "639.78px"
      }
    />
  ));

  // // Add the Dialog component before the return statement:
  // const EmailExistsDialog = () => (
  //   <Transition appear show={isOpen} as={Fragment}>
  //     <Dialog as="div" className="relative z-100" onClose={() => {}}>
  //       <Transition.Child
  //         as={Fragment}
  //         enter="ease-out duration-300"
  //         enterFrom="opacity-0"
  //         enterTo="opacity-100"
  //         leave="ease-in duration-200"
  //         leaveFrom="opacity-100"
  //         leaveTo="opacity-0"
  //       >
  //         <div
  //           className="fixed inset-0 bg-black/25"
  //           style={{ backgroundColor: "rgba(36, 16, 54, 0.3)" }}
  //         />
  //       </Transition.Child>

  //       <div className="fixed inset-0 overflow-y-auto">
  //         <div
  //           className="flex min-h-full items-center justify-center text-center"
  //           style={{ paddingLeft: "25px", paddingRight: "35.2px" }}
  //         >
  //           <Transition.Child
  //             as={Fragment}
  //             enter="ease-out duration-300"
  //             enterFrom="opacity-0 scale-95"
  //             enterTo="opacity-100 scale-100"
  //             leave="ease-in duration-200"
  //             leaveFrom="opacity-100 scale-100"
  //             leaveTo="opacity-0 scale-95"
  //           >
  //             <Dialog.Panel
  //               className="w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all"
  //               style={{ borderRadius: "12.33px" }}
  //             >
  //               <div className="w-full flex flex-col justify-start items-center gap-5">
  //                 <p
  //                   className="text-center font-bold text-2xl text-black"
  //                   style={{ lineHeight: "30.24px" }}
  //                 >
  //                   Email Sudah Terdaftar
  //                 </p>
  //                 <p
  //                   className="text-center font-medium text-sm text-black"
  //                   style={{ lineHeight: "17.78px" }}
  //                 >
  //                   <span>
  //                     Jika kamu ingin menggunakan email tersebut, seluruh
  //                     aktivitas yang ada pada akunmu akan hilang.
  //                   </span>
  //                   <br />
  //                   <br />
  //                   <span>Apakah kamu ingin reset akunmu?</span>
  //                 </p>
  //                 <div className="w-full flex flex-col justify-start items-stretch gap-3">
  //                   <ButtonV2
  //                     title="Reset Akun"
  //                     onClick={() => {
  //                       handleEmailOTP(userEmail);
  //                     }}
  //                   />
  //                   <ButtonV2
  //                     title="Cancel"
  //                     secondary={true}
  //                     onClick={() => {
  //                       setIsOpen(false);
  //                     }}
  //                   />
  //                 </div>
  //               </div>
  //             </Dialog.Panel>
  //           </Transition.Child>
  //         </div>
  //       </div>
  //     </Dialog>
  //   </Transition>
  // );

  return (
    <LayoutV2 padding="0">
      <div
        className="w-full sm:flex sm:justify-end relative"
        style={{
          padding:
            window.innerWidth < 1024 ? "27px 35.2px 0px 25px" : "32px 71px",
          minHeight:
            window.innerWidth < 1024
              ? window.location.pathname === "/signup"
                ? "1040px"
                : "916px"
              : window.location.pathname === "/signup"
              ? "758px"
              : "639.78px",
          height: window.innerHeight - (window.innerWidth < 1024 ? 210 : 145),
        }}
      >
        <div className="w-screen h-full absolute top-0 left-0">
          <Slider ref={charCardsRef} {...charCardsSettings}>
            {charCards.map((item) => item)}
          </Slider>
        </div>
        <div
          className="w-full h-full flex flex-col justify-start items-stretch relative z-10"
          style={{
            gap: "15px",
            width: window.innerWidth >= 1024 && "580px",
          }}
        >
          {window.innerWidth < 1024 && (
            <p className="text-left text-white">
              <span
                className="font-bold text-2xl"
                style={{ lineHeight: "30.24px" }}
              >
                Selamat datang
              </span>
              <br />
              <span
                className="font-medium text-base"
                style={{ lineHeight: "24px" }}
              >
                Raih masa depanmu bersama Exploration!
              </span>
            </p>
          )}
          <div
            className="w-full flex flex-col bg-white p-6 gap-5 sm:gap-4.5"
            style={{
              borderRadius: "12.33px",
            }}
          >
            <p
              className="text-left font-bold text-2xl text-black"
              style={{ lineHeight: "30.24px" }}
            >
              Daftar
            </p>
            <form onSubmit={onSubmit} className="w-full flex flex-col gap-4">
              <div
                className={`grid ${
                  window.innerWidth < 1024 ? "grid-rows-2" : "grid-cols-2"
                } gap-4 sm:gap-9`}
              >
                <FormField
                  id="fname"
                  title="Nama Depan"
                  placeholder="Masukkan nama depan"
                  isRequired={true}
                />
                <FormField
                  id="lname"
                  title="Nama Belakang"
                  placeholder="Masukkan nama belakang"
                  isRequired={true}
                />
              </div>
              <FormField
                id="email"
                title="Email"
                placeholder="Masukkan alamat e-mail"
                isRequired={true}
                error={emailError}
                onChange={(e) => {
                  const email = e.target.value;
                  validateEmail(email);
                }}
              />
              <FormField
                id="username"
                title="Username"
                placeholder="Masukkan username"
                isRequired={true}
                error={usernameError}
                onChange={async (e) => {
                  const username = e.target.value;
                  if (username.length > 2) {
                    await checkUsername(username);
                  }
                }}
              />
              <FormField
                id="password"
                title="Password"
                placeholder="Masukkan password"
                isPassword={true}
                isRequired={true}
              />
              <div className="w-full flex flex-col justify-start items-stretch gap-3 sm:gap-3.5">
                <div className="flex flex-col w-full justify-end items-center gap-2 mt-2">
                  <div className="flex flex-row justify-start items-start gap-2 w-full">
                    <div
                      onClick={() => {
                        setAgreeTNC(!agreeTNC);
                      }}
                      className={`border-2 ${
                        agreeTNC ? "border-primaryColor50" : "border-gray-400"
                      } rounded-md flex items-center justify-center`}
                      style={{
                        minHeight: "18px",
                        maxHeight: "18px",
                        minWidth: "18px",
                        maxWidth: "18px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className={`${
                          agreeTNC ? "bg-primaryColor" : "bg-transparent"
                        }`}
                        style={{
                          borderRadius: "3px",
                          minHeight: "10px",
                          maxHeight: "10px",
                          minWidth: "10px",
                          maxWidth: "10px",
                        }}
                      />
                    </div>
                    <p
                      className="text-left font-normal text-black1"
                      style={{ fontSize: "13px", lineHeight: "16.38px" }}
                    >
                      <span className="opacity-60">Saya setuju dengan </span>
                      <span>
                        <a
                          href="privacy"
                          rel="noreferrer"
                          target="_blank"
                          style={{ color: "#662E9B", fontWeight: "500" }}
                        >
                          kebijakan privasi & penggunaan{" "}
                          {window.innerWidth < 1024 && <br />} Exploration.
                        </a>
                      </span>
                    </p>
                  </div>
                  <div className="flex flex-row justify-start items-start gap-2 w-full">
                    <div
                      onClick={() => {
                        setAgreeLegal(!agreeLegal);
                      }}
                      className={`border-2 ${
                        agreeLegal ? "border-primaryColor50" : "border-gray-400"
                      } rounded-md flex items-center justify-center`}
                      style={{
                        minHeight: "18px",
                        maxHeight: "18px",
                        minWidth: "18px",
                        maxWidth: "18px",
                        cursor: "pointer",
                      }}
                    >
                      <div
                        className={`${
                          agreeLegal ? "bg-primaryColor" : "bg-transparent"
                        }`}
                        style={{
                          borderRadius: "3px",
                          minHeight: "10px",
                          maxHeight: "10px",
                          minWidth: "10px",
                          maxWidth: "10px",
                        }}
                      />
                    </div>
                    <p
                      className="text-left font-normal text-black1"
                      style={{ fontSize: "13px", lineHeight: "16.38px" }}
                    >
                      <span className="opacity-60">Saya setuju dengan </span>
                      <span>
                        <a
                          href="/terms-condition"
                          rel="noreferrer"
                          target="_blank"
                          style={{ color: "#662E9B", fontWeight: "500" }}
                        >
                          legal terms & condition Exploration.
                        </a>
                      </span>
                    </p>
                  </div>
                </div>
                <button
                  type="button"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                  className="text-right font-normal mt-2"
                  style={{
                    fontSize: "13px",
                    lineHeight: "16.38px",
                    color: "rgba(23, 23, 23, 0.6)",
                  }}
                >
                  Lupa password?
                </button>
                <ButtonV2
                  type="submit"
                  title="Daftar"
                  disabled={!agreeLegal || !agreeTNC}
                />
                <p
                  className="text-left font-normal"
                  style={{
                    fontSize: "13px",
                    lineHeight: "16.38px",
                    color: "#171717",
                  }}
                >
                  Sudah punya akun?{" "}
                  <a
                    href="/signin"
                    rel="noreferrer"
                    style={{ color: "#662E9B" }}
                  >
                    Masuk
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
      <RegisterEmailCheck
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onReset={() =>
          handleEmailOTP(
            userEmail,
            formData?.firstName,
            formData?.lastName,
            formData?.username
          )
        }
        userEmail={userEmail}
      />
    </LayoutV2>
  );
}
