import FileCard from "../../../components/FileCard";

export default function ReviewPage({ data, editData }) {
  const pasporData = [
    { id: "passportNumber", title: "Nomor Paspor Peserta" },
    { id: "passportExpiredDate", title: "Tanggal Expired Paspor Peserta" },
  ];

  const otherInfoData = [
    { id: "address", title: "Alamat Peserta" },
    { id: "allergies", title: "Persyaratan Diet / Alergi Peserta" },
    { id: "medicalCondition", title: "Kondisi Medis Peserta" },
    { id: "shirtSize", title: "Ukuran Baju Peserta" },
  ];

  const PasporContent = () => {
    return (
      <div className="w-full flex flex-col justify-start items-stretch gap-7">
        {pasporData.map((item, index) => (
          <div
            key={index}
            className="w-full flex flex-col sm:flex-row justify-start items-stretch sm:items-center gap-5 sm:gap-3"
          >
            <p
              className="text-left font-bold text-base text-black35"
              style={{
                fontSize: window.innerWidth >= 1024 && "15px",
                lineHeight: window.innerWidth < 1024 ? "12.33px" : "15.85px",
                width: window.innerWidth >= 1024 && "160px",
              }}
            >
              {item.title}
            </p>
            <p
              className="text-left font-normal text-base text-black35"
              style={{
                fontSize: window.innerWidth >= 1024 && "14px",
                lineHeight: window.innerWidth < 1024 ? "16px" : "19.96px",
              }}
            >
              {item.id === "passportExpiredDate"
                ? new Intl.DateTimeFormat("id-ID", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }).format(new Date(data[item.id]))
                : data[item.id]}
            </p>
          </div>
        ))}
        <FileCard
          file={data?.passportFile}
          viewFileURL={data?.passportRedirect}
        />
      </div>
    );
  };

  const OtherInfoContent = () => {
    return (
      <div className="w-full flex flex-col justify-start items-stretch gap-7">
        {otherInfoData.map((item, index) => (
          <div
            key={index}
            className="w-full flex flex-col sm:flex-row justify-start items-stretch sm:items-center gap-5 sm:gap-3"
          >
            <p
              className="text-left font-bold text-base text-black35"
              style={{
                fontSize: window.innerWidth >= 1024 && "15px",
                lineHeight: window.innerWidth < 1024 ? "12.33px" : "15.85px",
                width: window.innerWidth >= 1024 && "160px",
              }}
            >
              {item.title}
            </p>
            <p
              className="text-left font-normal text-base text-black35"
              style={{
                fontSize: window.innerWidth >= 1024 && "14px",
                lineHeight: window.innerWidth < 1024 ? "16px" : "19.96px",
              }}
            >
              {data[item.id]}
            </p>
          </div>
        ))}
      </div>
    );
  };

  const NotesContent = () => {
    return (
      <div className="w-full">
        <p
          className="text-left font-normal text-base text-black35"
          style={{ lineHeight: "16px" }}
        >
          {data.notes}
        </p>
      </div>
    );
  };

  const formData = [
    {
      title: "Foto Peserta",
      content: (
        <FileCard
          fileType="image"
          file={data?.photo}
          viewFileURL={data?.photoRedirect}
        />
      ),
    },
    {
      title: "Paspor Peserta",
      content: <PasporContent />,
    },
    { title: "Informasi Lainnya", content: <OtherInfoContent /> },
    { title: "Notes Tambahan", content: <NotesContent /> },
  ];

  return (
    <>
      <p
        className="text-left font-medium text-base"
        style={{ lineHeight: "20.16px", color: "#747474" }}
      >
        Silahkan review kembali form pelengkapan data Anda sebelum disubmit.
      </p>
      {formData.map((item, index) => (
        <div
          key={index}
          className="w-full flex flex-col justify-start items-end gap-5"
          style={{
            paddingBottom: index < formData.length - 1 && "30px",
            borderBottom:
              index < formData.length - 1 && "0.89px solid #2410364D",
          }}
        >
          <div className="w-full flex justify-between items-center">
            <p
              className="text-left font-bold text-xl text-primaryColor"
              style={{ lineHeight: "25.2px" }}
            >
              {item.title}
            </p>
            <button
              onClick={() => {
                editData(index + 1);
              }}
            >
              <img src="/icon_regis_draft.svg" />
            </button>
          </div>
          {item.content}
        </div>
      ))}
    </>
  );
}
