export default function DashedBorderBox({
  className,
  children,
  width,
  height,
  borderColor,
  borderWidth,
  borderRadius,
  dashLength,
  dashGap,
}) {
  return (
    <div
      className={className}
      style={{
        backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='${borderRadius}' ry='${borderRadius}' stroke='%23${
          borderColor ?? "333"
        }' stroke-width='${borderWidth ?? "4"}' stroke-dasharray='${
          dashLength ?? "6"
        }%2c ${
          dashGap ?? "14"
        }' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e")`,
        borderRadius: `${borderRadius}px`,
        width: width ?? "100%",
        height: height ?? "100px",
      }}
    >
      {children}
    </div>
  );
}
