import { useState } from "react";

export default function TextInput({
  className,
  placeholder,
  isRequired,
  value,
  onChange,
}) {
  const [text, setText] = useState(value ?? "");
  return (
    <input
      className={[className, "w-full py-3 px-4"].join(" ")}
      style={{ border: "0.62px solid #E0E0E1", borderRadius: "24px" }}
      value={text}
      onChange={(e) => {
        setText(e.target.value);
        onChange(e);
      }}
      placeholder={placeholder}
      required={isRequired}
    />
  );
}
