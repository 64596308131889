import { useState } from "react";
import { useSetRecoilState } from "recoil";
import { studentFormDataState } from "../../../atoms/studentFormDataState";

export default function NotePage({ data }) {
  const setData = useSetRecoilState(studentFormDataState);
  const [notes, setNotes] = useState(data?.notes);

  const handleNoteChange = (e) => {
    setNotes(e.target.value);
    setData((prevData) => ({
      ...prevData,
      notes: e.target.value,
    }));
  };

  return (
    <>
      <p
        className="text-left font-medium text-base"
        style={{ lineHeight: "20.16px", color: "#747474" }}
      >
        Silahkan tuliskan notes tambahan yang perlu diketahui oleh tim
        Exploration (jika ada)
      </p>
      <textarea
        placeholder="Masukkan notes tambahan"
        rows={7}
        value={notes}
        onChange={handleNoteChange}
        style={{ border: "0.62px solid #E0E0E1", borderRadius: "12px" }}
      ></textarea>
    </>
  );
}
