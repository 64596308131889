import { useEffect, useState } from "react";
import TextInput from "../../../components/TextInput";
import DropdownInput from "../../../components/DropdownInput";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { studentFormDataState } from "../../../atoms/studentFormDataState";

export default function OtherPage({ data }) {
  const setData = useSetRecoilState(studentFormDataState);

  const sizes = ["XS", "S", "M", "L", "XL", "XXL", "3XL", "4XL"];

  const handleChange = (field, value) => {
    setData((prev) => ({ ...prev, [field]: value }));
  };

  const fields = [
    {
      label: "Alamat Peserta",
      inputField: (
        <TextInput
          isRequired={true}
          placeholder="Masukkan alamat peserta"
          value={data?.address}
          onChange={(e) => handleChange("address", e.target.value)}
        />
      ),
    },
    {
      label: "Persyaratan Diet / Alergi Peserta",
      inputField: (
        <TextInput
          isRequired={true}
          placeholder="Masukkan diet / alergi peserta"
          value={data?.allergies}
          onChange={(e) => handleChange("allergies", e.target.value)}
        />
      ),
    },
    {
      label: "Kondisi Medis Peserta",
      inputField: (
        <TextInput
          isRequired={true}
          placeholder="Masukkan kondisi medis peserta"
          value={data?.medicalCondition}
          onChange={(e) => handleChange("medicalCondition", e.target.value)}
        />
      ),
    },
    {
      label: "Ukuran Baju Peserta",
      inputField: (
        <DropdownInput
          isRequired={true}
          placeholder="Pilih ukuran baju"
          options={sizes}
          value={data?.shirtSize}
          onChange={(e) => handleChange("shirtSize", e.target.value)}
        />
      ),
    },
  ];

  return (
    <>
      {fields.map((item, index) => (
        <div
          key={index}
          className="w-full flex flex-col justify-start items-stretch gap-3"
        >
          <p
            className="text-left font-bold text-base text-black35"
            style={{ lineHeight: "12.33px" }}
          >
            {item.label}
          </p>
          {item.inputField}
        </div>
      ))}
    </>
  );
}
