import { useEffect, useRef, useState } from "react";
import DashedBorderBox from "../../../components/DashedBorderBox";
import { useSetRecoilState } from "recoil";
import { studentFormDataState } from "../../../atoms/studentFormDataState";
import FileCard from "../../../components/FileCard";

export default function PhotoPage({ data }) {
  const fileInputRef = useRef();
  const setData = useSetRecoilState(studentFormDataState);
  const [selectedFile, setSelectedFile] = useState(data?.photo ?? null);
  const [filePreview, setFilePreview] = useState(data?.photoRedirect ?? null);
  const [errorMessage, setErrorMessage] = useState("");

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setErrorMessage("File terlalu besar! Maksimal 2MB.");
      } else {
        setErrorMessage("");
        let finalFile = file;

        const fileExtension = file.name.substring(file.name.lastIndexOf("."));
        const newFileName =
          `${data.studentName.trim().replace(/\s+/g, "_")}_Foto_Peserta` +
          fileExtension;
        finalFile = new File([file], newFileName, { type: file.type });

        setSelectedFile(finalFile);
        setFilePreview(URL.createObjectURL(finalFile));
        setData((prevData) => ({
          ...prevData,
          photo: finalFile,
          photoRedirect: URL.createObjectURL(finalFile),
        }));
      }
    }
  };

  const handleRemoveFile = () => {
    setSelectedFile();
    setFilePreview();
    setErrorMessage("");
    setData((prevData) => ({
      ...prevData,
      photo: null,
      photoRedirect: null,
    }));
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Reset file input
    }
  };

  return (
    <>
      <div
        className="text-left font-medium text-base"
        style={{ lineHeight: "20.16px", color: "#747474" }}
      >
        Ketentuan Foto:
        <br />
        <span>
          <ol className="ml-5" style={{ listStyleType: "decimal" }}>
            <li>Foto setengah badan dengan menampilkan wajah dengan jelas</li>
            <li>Background warna polos</li>
          </ol>
        </span>
      </div>
      <p
        className="text-left font-medium text-base"
        style={{ lineHeight: "20.16px", color: "#747474" }}
      >
        Silahkan upload foto Anda
      </p>

      {selectedFile ? (
        <FileCard
          fileType="image"
          file={selectedFile}
          viewFileURL={filePreview}
          removeFile={handleRemoveFile}
        />
      ) : (
        <DashedBorderBox
          className="flex flex-col justify-center items-center"
          borderColor="CACACA"
          borderRadius="6"
          height="265px"
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />

          <button
            type="button"
            onClick={handleButtonClick}
            className="flex flex-col justify-center items-center cursor-pointer"
          >
            <img src="/icon_image_input.png" alt="Upload icon" />
            <div className="w-full flex flex-col justify-start items-center gap-1">
              <p
                className="text-center font-medium text-sm text-primaryColor"
                style={{ lineHeight: "17.64px" }}
              >
                Klik untuk Upload
              </p>
              <p
                className="text-center font-medium text-xs text-black35"
                style={{ lineHeight: "15.12px" }}
              >
                (Max. File size: 2 MB)
              </p>
              {errorMessage && (
                <p
                  className="text-center font-medium text-xs text-red-500"
                  style={{ lineHeight: "15.12px" }}
                >
                  {errorMessage}
                </p>
              )}
            </div>
          </button>
        </DashedBorderBox>
      )}
    </>
  );
}
