import { useEffect, useRef, useState } from "react";
import DashedBorderBox from "../../../components/DashedBorderBox";
import TextInput from "../../../components/TextInput";
import { useSetRecoilState } from "recoil";
import { studentFormDataState } from "../../../atoms/studentFormDataState";
import FileCard from "../../../components/FileCard";

export default function PassportPage({ data }) {
  const setData = useSetRecoilState(studentFormDataState);
  const fileInputRef = useRef();
  const [passportNumber, setPassportNumber] = useState(
    data?.passportNumber ?? ""
  );
  const [selectedFile, setSelectedFile] = useState(data?.passportFile ?? null);
  const [filePreview, setFilePreview] = useState(
    data?.passportRedirect ?? null
  );
  const [errorMessage, setErrorMessage] = useState("");

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setErrorMessage("File terlalu besar! Maksimal 2MB.");
      } else {
        setErrorMessage("");
        let finalFile = file;

        const fileExtension = file.name.substring(file.name.lastIndexOf("."));
        const newFileName =
          `${data.studentName.trim().replace(/\s+/g, "_")}_Paspor_Peserta` +
          fileExtension;
        finalFile = new File([file], newFileName, { type: file.type });

        setSelectedFile(finalFile);
        setFilePreview(URL.createObjectURL(finalFile));
        setData((prevData) => ({
          ...prevData,
          passportFile: finalFile,
          passportRedirect: URL.createObjectURL(finalFile),
        }));
      }
    }
  };

  const handleRemoveFile = () => {
    setData((prevData) => ({
      ...prevData,
      passportFile: null,
      passportRedirect: null,
    }));
    setSelectedFile();
    setFilePreview();
    setErrorMessage("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handlePassportNumberChange = (e) => {
    setPassportNumber(e.target.value);
    setData((prevData) => ({
      ...prevData,
      passportNumber: e.target.value,
    }));
  };

  return (
    <>
      <div className="w-full flex flex-col tablet:flex-row justify-start items-stretch tablet:items-center gap-3">
        <p
          className="text-left font-bold text-base text-black35"
          style={{
            lineHeight: window.innerWidth < 1024 ? "12.33px" : "15.85px",
            minWidth: window.innerWidth >= 1024 && "160px",
            maxWidth: window.innerWidth >= 1024 && "160px",
          }}
        >
          Nomor Paspor Peserta
        </p>
        <TextInput
          placeholder="Masukkan nomor paspor peserta"
          value={passportNumber}
          onChange={handlePassportNumberChange}
          isRequired={true}
        />
      </div>
      <div className="w-full flex flex-col tablet:flex-row justify-start items-stretch tablet:items-center gap-3">
        <p
          className="text-left font-bold text-base text-black35"
          style={{
            lineHeight: window.innerWidth < 1024 ? "12.33px" : "15.85px",
            minWidth: window.innerWidth >= 1024 && "160px",
            maxWidth: window.innerWidth >= 1024 && "160px",
          }}
        >
          Tanggal Expired Paspor Peserta
        </p>
        <input
          type="date"
          className="w-full py-3 px-4"
          style={{ border: "0.62px solid #E0E0E1", borderRadius: "24px" }}
          onFocus={(e) => e.target.showPicker && e.target.showPicker()}
          onChange={(e) => {
            setData((prev) => ({
              ...prev,
              passportExpiredDate: new Date(e.target.value).toISOString(),
            }));
          }}
        />
      </div>
      <div className="w-full flex flex-col justify-start items-stretch gap-3">
        <p
          className="text-left font-bold text-base text-black35"
          style={{ lineHeight: "12.33px " }}
        >
          Copy Paspor Peserta
        </p>
        <div
          className="text-left font-medium text-base"
          style={{ lineHeight: "20.16px", color: "#747474" }}
        >
          Pastikan paspor peserta memenuhi persyaratan di bawah:
          <br />
          <span>
            <ol className="ml-5" style={{ listStyleType: "decimal" }}>
              <li>Menampilkan halaman biodata</li>
              <li>
                Paspor wajib aktif setidaknya hingga 29 Desember 2025 (6 bulan
                setelah
                {window.innerWidth < 1024 ? <br /> : " "}
                berangkat program)
              </li>
            </ol>
          </span>
        </div>
      </div>
      <p
        className="text-left font-medium text-base"
        style={{ lineHeight: "20.16px", color: "#747474" }}
      >
        Silahkan upload paspor Anda
      </p>
      {selectedFile ? (
        <FileCard
          file={selectedFile}
          viewFileURL={filePreview}
          removeFile={handleRemoveFile}
        />
      ) : (
        <DashedBorderBox
          className="flex flex-col justify-center items-center"
          borderColor="CACACA"
          borderRadius="6"
          height="265px"
        >
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            style={{ display: "none" }}
          />

          <button
            type="button"
            onClick={handleButtonClick}
            className="flex flex-col justify-center items-center cursor-pointer"
          >
            <img src="/icon_image_input.png" alt="Upload icon" />
            <div className="w-full flex flex-col justify-start items-center gap-1">
              <p
                className="text-center font-medium text-sm text-primaryColor"
                style={{ lineHeight: "17.64px" }}
              >
                Klik untuk Upload
              </p>
              <p
                className="text-center font-medium text-xs text-black35"
                style={{ lineHeight: "15.12px" }}
              >
                (Max. File size: 2 MB)
              </p>
              {errorMessage && (
                <p
                  className="text-center font-medium text-xs text-red-500"
                  style={{ lineHeight: "15.12px" }}
                >
                  {errorMessage}
                </p>
              )}
            </div>
          </button>
        </DashedBorderBox>
      )}
    </>
  );
}
