import { MdArrowBack, MdArrowForward } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { api, apiAnom, notifError } from "../../utils/api";
import { useRecoilValue } from "recoil";
import { authState } from "../../atoms/authState";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import PageLoading from "../PageLoading";
import CharactersData from "../tmb_result_revamp/CharactersData.json";
import { domToPng } from "modern-screenshot";
import LayoutV2 from "../../components/Layout_V2";
import jsPDF from "jspdf";
import { saveAs } from "file-saver";
import TMBResultPDFCover from "./TMBResultPDFCover.html";
import html2canvas from "html2canvas";
import moment from "moment-timezone";
import "moment/locale/id";
import TMBResultPDF from "./TMBResultPDF";

export default function TMBResultKarakter({ screenWidth, screenHeight }) {
  const navigate = useNavigate();
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const [resultData, setResultData] = useState();
  const [selectedChar, setSelectedChar] = useState(0);
  const [isDownloadingPdf, setIsDownloadingPdf] = useState();
  const [isDownloadingIGCard, setIsDownloadingIGCard] = useState(false);
  const pdf = new jsPDF({
    unit: "px",
    format: [1080, 1920],
  });

  const educationLevels = [
    { id: 0, name: "Not Applicable" },
    { id: 1, name: "Kelas 10" },
    { id: 2, name: "Kelas 11" },
    { id: 3, name: "Kelas 12" },
    { id: 4, name: "SMP (atau sederajat)" },
    { id: 5, name: "Lulusan SMA (atau sederajat)" },
    { id: 6, name: "Kelas 7" },
    { id: 7, name: "Kelas 8" },
    { id: 8, name: "Kelas 9" },
  ];

  const tmbPDFCharImage = (type) => {
    switch (type) {
      case "R":
        return "/tmb_result/pdf_char_r.svg";
      case "I":
        return "/tmb_result/pdf_char_i.svg";
      case "A":
        return "/tmb_result/pdf_char_a.svg";
      case "S":
        return "/tmb_result/pdf_char_s.svg";
      case "E":
        return "/tmb_result/pdf_char_e.svg";
      case "C":
        return "/tmb_result/pdf_char_c.svg";
      default:
        return "/tmb_result/pdf_char_r.svg";
    }
  };

  useEffect(() => {
    if (!auth.isAuth) {
      navigate("/signin");
    }
    _api
      .get(`/tmb/test-result-detail/${window.location.pathname.split("/")[3]}`)
      .then((res) => {
        setResultData(res.data.data);
      })
      .catch(notifError);
  }, []);

  // async function downloadResult() {
  //   setIsDownloadingPdf(true);
  //   await api
  //     .get(
  //       `/tmb/test-result-detail-pdf/${window.location.pathname.split("/")[3]}`,
  //       { responseType: "arraybuffer" }
  //     )
  //     .then((response) => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute(
  //         "download",
  //         `my-tmb-result-${window.location.pathname.split("/")[3]}.pdf`
  //       );
  //       document.body.appendChild(link);
  //       link.click();
  //       setIsDownloadingPdf(false);
  //     })
  //     .catch((error) => {
  //       setIsDownloadingPdf(false);
  //       window.Swal.fire({
  //         title: "Perhatian!",
  //         text: `${
  //           error.message === "Request failed with status code 412"
  //             ? "Silakan lengkapi data dirimu dahulu"
  //             : error.message
  //         }`,
  //         icon: "warning",
  //         confirmButtonText: `${
  //           error.message === "Request failed with status code 412"
  //             ? "Edit Profil"
  //             : "Tutup"
  //         }`,
  //       }).then(() => {
  //         if (error.message === "Request failed with status code 412") {
  //           navigate("/profile-edit");
  //         }
  //       });
  //       return;
  //     });
  // }

  function downloadResult() {
    // const pdf = new jsPDF("p", "pt", "a4", true);
    const pdf = new jsPDF({
      unit: "px",
      format: [2480, 3508],
    });
    setIsDownloadingPdf(true);
    const htmlContent = TMBResultPDF(
      `${auth.fname} ${auth.lname}`,
      moment(resultData[0].created_at)
        .tz("Asia/Jakarta")
        .locale("id")
        .format("dddd, D MMMM YYYY"),
      educationLevels[
        educationLevels.findIndex((edu) => edu.id === auth.education_level_id)
      ].name,
      auth.school_name === "Others"
        ? auth.custom_school_name
        : auth.school_name,
      auth.major,
      resultData[0].major_recommendations ?? [],
      resultData[0].UnivLogoBase64 ?? [],
      tmbPDFCharImage(resultData[0].ctgr_code),
      tmbPDFCharImage(resultData[1].ctgr_code),
      tmbPDFCharImage(resultData[2].ctgr_code)
    );
    const container = document.createElement("div");
    container.innerHTML = htmlContent;
    container.style.position = "absolute";
    container.style.top = "0";
    container.style.left = "0";
    container.style.zIndex = "-1";
    document.body.appendChild(container);
    document.body.style.overflow = "hidden";
    document.body.style.touchAction = "none";
    document.body.style.position = screenWidth < 1024 && "fixed";
    document.body.style.width = "100%";

    const pageWidth = 2480;
    const pageHeight = 3508;

    const capturePage = (startY, pageNum) => {
      return new Promise((resolve) => {
        // Adjust container style for pagination
        container.style.top = `-${startY}px`;
        container.style.left = "0";

        setTimeout(() => {
          html2canvas(container, {
            scale: 1,
            width: pageWidth,
            height: pageHeight,
            x: 0,
            y: startY,
          }).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            if (pageNum > 1) {
              pdf.addPage([pageWidth, pageHeight]);
            }
            pdf.addImage(
              imgData,
              "PNG",
              0,
              0,
              pageWidth,
              pageHeight,
              "",
              "FAST"
            );
            resolve();
          });
        }, 100); // Small delay to ensure proper rendering
      });
    };

    const contentHeight = container.scrollHeight;
    const totalPages = Math.ceil(contentHeight / pageHeight);

    const renderPages = async () => {
      for (let page = 0; page < totalPages; page++) {
        const startY = page * pageHeight;
        await capturePage(startY, page + 1);
      }
      const pdfBlob = pdf.output("blob");
      saveAs(
        pdfBlob,
        `my-tmb-result-${window.location.pathname.split("/")[3]}.pdf`
      );
      document.body.removeChild(container);
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
      document.body.style.position = "";
      document.body.style.width = "";
      setIsDownloadingPdf(false);
    };

    renderPages();
  }

  const charColor = (code) => {
    switch (code) {
      case "R":
        return "#FDCF30";
      case "I":
        return "#FFFFFF";
      case "A":
        return "#FD5244";
      case "S":
        return "#FE5244";
      case "E":
        return "#662E9D";
      case "C":
        return "#57C2D2";
      default:
        return "Unique";
    }
  };

  // DO NOT DELETE. FOR FUTURE REFERENCE
  //     case "R": Doers
  //     case "I": Thinkers
  //     case "A": Creators
  //     case "S": Helpers
  //     case "E": Persuaders
  //     case "C": Organizer

  const charTextColor = (code) => {
    switch (code) {
      case "R":
        return "white";
      case "C":
        return "black";
      default:
        return "white";
    }
  };

  // function instagramShare() {
  //   setIsDownloadingIGCard(true);
  // domToPng(document.getElementById("divToPrint")).then((dataUrl) => {
  //   const link = document.createElement("a");
  //   link.download = `my-characters-${
  //     window.location.pathname.split("/")[3]
  //   }.png`;
  //   link.href = dataUrl;
  //   link.click();
  //   setIsDownloadingIGCard(false);
  // });
  // }

  const instagramShare = () => {
    // Step 1: Open a new tab
    const newTab = window.open("", "_blank");

    if (!newTab) return; // In case popups are blocked by the browser

    // Step 2: Add HTML content to the new tab
    newTab.document.write(`
      <html>
        <head>
          <title>Download PDF</title>
          <style>
            body {
              width: 1080px;
              height: 1920px;
              position: relative;
              font-family: Arial, sans-serif;
            }
            .center-text {
              position: absolute;
              top: 234px;
              left: 50%;
              transform: translateX(-50%);
              font-weight: 700;
              font-size: 26px;
              line-height: 32.76px;
              letter-spacing: -4%;
              text-align: center;
              color: ${charTextColor(resultData[0].code)};
            }
          </style>
        </head>
        <body>
          <div id="divToPrint">
            <p class="center-text">Hi, ${auth.fname} ${auth.lname}</p>
            <img
              src="/ig_card/ig_card_${resultData[0].code.toLowerCase()}.svg"
              style="width: 100%; height: auto; object-fit: cover;"
            />
          </div>
        </body>
      </html>
    `);

    // Wait for the new tab's content to be fully loaded
    newTab.document.close();

    // Step 3: Trigger image generation and download
    setTimeout(() => {
      domToPng(newTab.document.getElementById("divToPrint"))
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = `my-characters-${
            window.location.pathname.split("/")[3]
          }.png`;
          link.href = dataUrl;
          link.click();
          setIsDownloadingIGCard(false);
        })
        .then(() => {
          newTab.close();
        });
    }, 1000); // Small delay to ensure content is fully loaded
  };

  if (!resultData) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0px">
      <button
        onClick={() => {
          navigate(
            `/tmb-result-detail/jurusan/${
              window.location.pathname.split("/")[3]
            }`
          );
        }}
        className="fixed w-12 sm:w-44 h-12 rounded-lg bg-white flex justify-center items-center sm:gap-2 z-50"
        style={{
          top: screenWidth >= 1024 ? "128px" : "95px",
          right:
            screenWidth >= 1920
              ? "120px"
              : screenWidth >= 1024
              ? "55px"
              : "30px",
          scale: screenWidth >= 1920 && "125%",
          boxShadow: "0px 0px 20px 0px rgba(64, 69, 79, 0.4)",
        }}
      >
        <img
          src="/icon_toga_primary.svg"
          className="relative z-10"
          style={{
            width: screenWidth >= 1024 ? "19px" : "24px",
            height: screenWidth >= 1024 ? "19px" : "24px",
          }}
        />
        {screenWidth >= 1024 && (
          <p
            className="text-left font-semibold text-sm text-primaryColor"
            style={{ lineHeight: "21px" }}
          >
            Lihat jurusanmu
          </p>
        )}
      </button>
      <div className="relative w-full">
        <img
          src={
            screenWidth < 1024
              ? "/bg_tmb_result_karakter.png"
              : "/bg_tmb_result_karakter.svg"
          }
          className="w-full h-auto object-cover"
          style={{ height: screenWidth >= 1920 && "920px" }}
        />
        <button
          onClick={() => {
            navigate(
              `/tes-minat-result/${window.location.pathname.split("/")[3]}`
            );
          }}
          className="absolute z-10"
          style={{
            left:
              screenWidth >= 1920
                ? "120px"
                : screenWidth >= 1024
                ? "74.78px"
                : "34px",
            top:
              screenWidth >= 1920
                ? "60px"
                : screenWidth >= 1024
                ? "46.33px"
                : "52px",
          }}
        >
          <MdArrowBack
            className="h-auto text-white"
            style={{
              width: screenWidth >= 1024 ? "26px" : "20px",
              scale: screenWidth >= 1920 && "150%",
            }}
          />
        </button>
        <div
          className="w-full h-full absolute top-0 flex flex-col justify-start items-stretch sm:items-center px-12"
          style={{ paddingTop: screenWidth >= 1920 ? "140px" : "69px" }}
        >
          <p
            className="text-center font-bold text-xs sm:text-sm text-mustard3 uppercase"
            style={{
              letterSpacing: screenWidth >= 1024 ? "1.33px" : "2px",
              lineHeight: screenWidth >= 1024 ? "21px" : "18px",
              scale: screenWidth >= 1920 && "125%",
            }}
          >
            Tipe Karakter Kamu
          </p>
          <p
            className="text-center font-bold text-white"
            style={{
              marginTop: screenWidth >= 1024 ? "14px" : "22px",
              maxWidth: "510.22px",
              letterSpacing: screenWidth >= 1024 ? "-0.8px" : "-1.2px",
              lineHeight: screenWidth >= 1024 ? "43.2px" : "28.8px",
              fontSize: screenWidth >= 1024 ? "36px" : "24px",
            }}
          >
            Kenalin, karakter kuatmu adalah{" "}
            <span className="text-mustard3">
              {resultData[0].label_short.split(" ")[1]},{" "}
              {resultData[1].label_short.split(" ")[1]},{" "}
              {resultData[2].label_short.split(" ")[1]}!
            </span>
          </p>
          <div
            className={`${
              screenWidth < 1024 &&
              "w-full absolute bottom-36 left-0 flex justify-center"
            }`}
          >
            <div
              className="flex flex-row justify-center items-end relative"
              style={{
                marginTop:
                  screenWidth >= 1920 ? "135px" : screenWidth >= 1024 && "12px",
                width: screenWidth < 1024 && "140.16px",
              }}
            >
              <img
                src={`/tmb_char_illu/illu_tmb_top3_${
                  screenWidth < 1024 ? "mobile" : "desktop"
                }_${resultData[1].ctgr_code.toLowerCase()}.${
                  screenWidth < 1024 ? "png" : "svg"
                }`}
                className="object-cover absolute bottom-0 z-40"
                style={{
                  height: screenWidth >= 1024 ? "366px" : "143px",
                  left: screenWidth >= 1024 ? "-250px" : "-100px",
                }}
              />
              <img
                src={`/tmb_char_illu/illu_tmb_top3_${
                  screenWidth < 1024 ? "mobile" : "desktop"
                }_${resultData[0].ctgr_code.toLowerCase()}.${
                  screenWidth < 1024 ? "png" : "svg"
                }`}
                className="object-contain relative z-50"
                style={{
                  height: screenWidth >= 1024 ? "366px" : "143px",
                }}
              />
              <img
                src={`/tmb_char_illu/illu_tmb_top3_${
                  screenWidth < 1024 ? "mobile" : "desktop"
                }_${resultData[2].ctgr_code.toLowerCase()}.${
                  screenWidth < 1024 ? "png" : "svg"
                }`}
                className="object-cover absolute bottom-0 z-30"
                style={{
                  height: screenWidth >= 1024 ? "366px" : "143px",
                  right: screenWidth >= 1024 ? "-250px" : "-100px",
                }}
              />
            </div>
          </div>
          <div
            className={`${
              screenWidth < 1024 &&
              "flex w-full h-full relative bottom-0 left-0 px-12"
            }`}
          >
            <div
              className={`w-full px-0 flex justify-between sm:justify-center ${
                screenWidth < 1024 && "absolute left-0"
              }`}
              style={{
                marginTop:
                  screenWidth >= 1920
                    ? "40px"
                    : screenWidth >= 1024 && "23.14px",
                gap: "10px",
                scale: screenWidth >= 1920 && "140%",
                bottom: screenWidth < 1024 && "34px",
              }}
            >
              <button
                onClick={() => {
                  downloadResult();
                }}
                className="h-12 flex flex-row justify-center items-center gap-3"
                style={{
                  borderRadius: "6.67px",
                  // width: screenWidth >= 1024 ? "248px" : "235px",
                  backgroundColor: isDownloadingPdf
                    ? "gray"
                    : "rgba(255, 202, 45, 1)",
                  paddingLeft: screenWidth >= 1024 ? "20.5px" : "47px",
                  paddingRight: screenWidth >= 1024 ? "20.5px" : "47px",
                }}
              >
                <img
                  src="/icon_download.svg"
                  className="object-contain w-5 h-4"
                />
                <p
                  className="text-left font-bold text-base text-black whitespace-nowrap"
                  style={{ lineHeight: "20.16px" }}
                >
                  {isDownloadingPdf ? "downloading..." : "Download PDF"}
                </p>
              </button>
              <button
                disabled={isDownloadingIGCard}
                onClick={() => {
                  setTimeout(() => {
                    instagramShare();
                  }, 300);
                }}
                className="w-12 h-12 sm:w-max sm:gap-3 flex flex-row justify-center items-center sm:px-6"
                style={{
                  borderRadius: "6.67px",
                  backgroundColor: isDownloadingIGCard
                    ? "gray"
                    : "rgba(255, 202, 45, 1)",
                }}
              >
                <img
                  src={`/icon_instagram.${screenWidth < 1024 ? "png" : "svg"}`}
                  className="object-contain w-4 h-4"
                />
                {screenWidth >= 1024 && (
                  <p
                    className="text-left font-bold text-base text-black"
                    style={{ lineHeight: "20.16px" }}
                  >
                    Share
                  </p>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="relative w-full"
        style={{ height: screenWidth >= 1024 ? "580px" : "706px" }}
      >
        <img
          src={
            screenWidth >= 1024
              ? "/illu_tmb_result_circle_desktop.svg"
              : "/illu_tmb_result_circle.svg"
          }
          className="absolute -z-1"
          style={{
            top: screenWidth >= 1024 ? "103px" : "127px",
            right: screenWidth >= 1024 ? "161px" : "24px",
            opacity: "40%",
          }}
        />
        <div
          className="w-full flex flex-col justify-start items-start px-4 sm:px-18 fhd:px-60"
          style={{
            paddingTop: screenWidth >= 1024 ? "70px" : "39px",
          }}
        >
          <div
            className="w-full flex flex-col sm:flex-row items-center sm:justify-between"
            style={{
              gap: screenWidth >= 1024 && "94px",
              paddingRight: screenWidth >= 1024 && "13px",
            }}
          >
            <div
              className="flex flex-col justify-start items-center sm:items-stretch"
              style={{ width: screenWidth >= 1024 && "656px" }}
            >
              <p
                className="text-center sm:text-left font-bold text-2xl sm:text-40px text-black"
                style={{
                  width: screenWidth < 1024 && "272px",
                  lineHeight: screenWidth >= 1024 ? "48px" : "28.8px",
                }}
              >
                Kenalin, karaktermu!
              </p>
              <div
                className="w-full flex flex-col justify-start items-center"
                style={{ marginTop: screenWidth >= 1024 ? "21.62px" : "20px" }}
              >
                <div className="w-full flex flex-row justify-between items-center">
                  {screenWidth < 1024 && (
                    <button
                      onClick={() => {
                        setSelectedChar(selectedChar - 1);
                      }}
                      disabled={selectedChar === 0}
                    >
                      <MdArrowBack
                        className="text-primaryColor h-5 w-auto"
                        style={{
                          opacity: selectedChar === 0 ? "35%" : "100%",
                        }}
                      />
                    </button>
                  )}
                  <div
                    className="text-center font-bold text-2xl sm:text-3xl text-black relative"
                    style={{
                      lineHeight: screenWidth >= 1024 ? "38.4px" : "28.8px",
                    }}
                  >
                    <div
                      className="w-full absolute -bottom-1 -z-1"
                      style={{
                        height: "8px",
                        backgroundColor: charColor(
                          resultData[selectedChar].ctgr_code
                        ),
                      }}
                    />
                    {screenWidth < 1024 ? (
                      <span>
                        {resultData[selectedChar].label_short}
                        <br />
                        {resultData[selectedChar].label_long.split(" ")[2]}
                      </span>
                    ) : (
                      resultData[selectedChar].label_long
                    )}
                  </div>
                  {screenWidth < 1024 && (
                    <button
                      onClick={() => {
                        setSelectedChar(selectedChar + 1);
                      }}
                      disabled={selectedChar === resultData?.length - 1}
                    >
                      <MdArrowForward
                        className="text-primaryColor h-5 w-auto"
                        style={{
                          opacity:
                            selectedChar === resultData?.length - 1
                              ? "35%"
                              : "100%",
                        }}
                      />
                    </button>
                  )}
                </div>
              </div>
              {screenWidth < 1024 && (
                <div
                  className="flex flex-row gap-1 justify-center items-center"
                  style={{ marginTop: "14px" }}
                >
                  {resultData?.map((_, index) => (
                    <div
                      key={index}
                      className="rounded-full"
                      style={{
                        width: "6px",
                        height: "6px",
                        backgroundColor: "rgba(169, 169, 169, 1)",
                        opacity: selectedChar === index ? "100%" : "35%",
                      }}
                    />
                  ))}
                </div>
              )}
              <div className="w-full px-3 sm:px-0">
                <p
                  className="text-justify font-normal text-black"
                  style={{
                    height: screenWidth >= 1024 ? "276px" : "150px",
                    fontSize: screenWidth >= 1024 ? "17.78px" : "16px",
                    lineHeight: screenWidth >= 1024 ? "26.67px" : "24.8px",
                    letterSpacing: screenWidth >= 1024 && "-0.64px",
                    marginTop: screenWidth >= 1024 ? "13px" : "13px",
                  }}
                  dangerouslySetInnerHTML={{
                    __html:
                      screenWidth < 1024
                        ? CharactersData[
                            CharactersData.findIndex(
                              (char) =>
                                char.ctgr_code ===
                                resultData[selectedChar].ctgr_code
                            )
                          ].desc_mobile.substring(0, 240)
                        : CharactersData[
                            CharactersData.findIndex(
                              (char) =>
                                char.ctgr_code ===
                                resultData[selectedChar].ctgr_code
                            )
                          ].desc_desktop.substring(0, 600),
                  }}
                />
              </div>
            </div>
            <img
              src={`/tmb_char_illu/illu_tmb_${
                screenWidth < 1024 ? "mobile" : "desktop"
              }_${resultData[selectedChar].ctgr_code.toLowerCase()}.${
                screenWidth < 1024 ? "png" : "svg"
              }`}
              className="h-auto object-cover"
              style={{
                height: screenWidth >= 1024 ? "385px" : "353px",
                marginTop: screenWidth >= 1024 ? "37px" : "21px",
              }}
            />
          </div>
          {screenWidth >= 1024 && (
            <div className="w-full flex justify-center mt-7">
              <div className="flex flex-row justify-center items-center gap-9">
                <button
                  onClick={() => {
                    setSelectedChar(selectedChar - 1);
                  }}
                  disabled={selectedChar === 0}
                >
                  <ChevronLeftIcon
                    className="h-5 w-auto"
                    style={{
                      opacity: selectedChar === 0 ? "35%" : "100%",
                      color: "rgba(169, 169, 169)",
                    }}
                  />
                </button>
                <div className="flex flex-row gap-1 justify-center items-center">
                  {resultData?.map((_, index) => (
                    <div
                      key={index}
                      className="rounded-full"
                      style={{
                        width: "6px",
                        height: "6px",
                        backgroundColor: "rgba(169, 169, 169, 1)",
                        opacity: selectedChar === index ? "100%" : "35%",
                      }}
                    />
                  ))}
                </div>
                <button
                  onClick={() => {
                    setSelectedChar(selectedChar + 1);
                  }}
                  disabled={selectedChar === resultData?.length - 1}
                >
                  <ChevronRightIcon
                    className="h-5 w-auto"
                    style={{
                      opacity:
                        selectedChar === resultData?.length - 1
                          ? "35%"
                          : "100%",
                      color: "rgba(169, 169, 169)",
                    }}
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="relative w-full">
        <img
          src={
            screenWidth >= 1024
              ? "/bg_tmb_result_footer_desktop.svg"
              : "/bg_tmb_result_footer.svg"
          }
          className="w-full object-cover"
          style={{
            height: screenWidth >= 1920 && "500px",
          }}
        />
        <div
          className="w-full flex flex-col justify-start items-center absolute top-0"
          style={{ paddingTop: screenWidth >= 1024 ? "115px" : "76px" }}
        >
          <p
            className="text-center font-bold text-2xl sm:text-40px text-white"
            style={{
              width: screenWidth >= 1024 ? "460px" : "355.33px",
              lineHeight: screenWidth >= 1024 ? "48px" : "28.8px",
              letterSpacing: "-1.3px",
            }}
          >
            Lihat hasil Rekomendasi
            <br />
            Jurusanmu yang sesuai!
          </p>
          <div
            className="flex flex-col sm:flex-row justify-start items-center gap-3 sm:gap-9"
            style={{ marginTop: screenWidth >= 1024 ? "58.33px" : "52px" }}
          >
            <button
              onClick={() => {
                navigate(
                  `/tmb-result-detail/jurusan/${
                    window.location.pathname.split("/")[3]
                  }`
                );
              }}
              className="bg-primaryColor flex items-center justify-center"
              style={{
                width: screenWidth >= 1024 ? "166px" : "250px",
                height: screenWidth >= 1024 ? "36.44px" : "50px",
                borderRadius: screenWidth >= 1024 ? "5.33px" : "8.67px",
              }}
            >
              <p
                className="text-center font-semibold text-white"
                style={{
                  fontSize: screenWidth >= 1024 ? "14.22px" : "16px",
                  lineHeight: screenWidth >= 1024 ? "21.33px" : "24px",
                }}
              >
                Hasil Jurusan
              </p>
            </button>
          </div>
        </div>
        <img
          src={
            screenWidth >= 1024
              ? "/illu_tmb_result_sierra_footer_desktop.png"
              : "/illu_tmb_result_sierra_footer.png"
          }
          className="absolute bottom-0 sm:right-0"
          style={{ left: screenWidth < 1024 && "2.17px" }}
        />
      </div>

      {/* IG Share Card */}
      {/* <div
        id="divToPrint"
        className={`${!isDownloadingIGCard && "hidden"}`}
        style={{
          width: "1080px",
          height: "1920px",
          // minWidth: "1080px",
          // minHeight: "1920px",
          // maxWidth: "1080px",
          // maxHeight: "1920px",
          position: "relative",
        }}
      >
        <p
          style={{
            position: "absolute",
            top: "234px",
            transform: "translate(-50%, 0%)",
            left: "50%",
            fontWeight: 700,
            fontSize: "26px",
            lineHeight: "32.76px",
            letterSpacing: "-4%",
            textAlign: "center",
            color: charTextColor(resultData[0].code),
          }}
        >{`Hi, ${auth.fname} ${auth.lname}`}</p>
        <img
          src={`/ig_card/ig_card_${resultData[0].code.toLowerCase()}.svg`}
          className="w-full h-auto object-cover"
        />
      </div> */}
    </LayoutV2>
  );
}
