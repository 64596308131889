import { useRecoilValue } from "recoil";
import { studentFormDataState } from "../../../atoms/studentFormDataState";

export default function WelcomePage({ topic }) {
  const studentData = useRecoilValue(studentFormDataState);
  return (
    <>
      <div className="w-full flex flex-col justify-center items-stretch sm:flex-row sm:justify-start sm:items-end gap-4">
        <p
          className="text-left font-bold text-base text-black"
          style={{
            lineHeight: "12.33px",
            minWidth: window.innerWidth > 1024 && "160px",
          }}
        >
          Nama Peserta
        </p>
        <p
          className="text-left font-normal text-base text-black"
          style={{ lineHeight: "16px" }}
        >
          {studentData.studentName ?? ""}
        </p>
      </div>
      <div className="w-full flex flex-col justify-center items-stretch sm:flex-row sm:justify-start sm:items-end gap-4">
        <p
          className="text-left font-bold text-base text-black mt-3"
          style={{
            lineHeight: "12.33px",
            minWidth: window.innerWidth > 1024 && "160px",
          }}
        >
          Topik
        </p>
        <p
          className="text-left font-normal text-base text-black"
          style={{ lineHeight: "16px" }}
        >
          {topic}
          {/* {studentData?.topic ?? "-"} */}
        </p>
      </div>
    </>
  );
}
