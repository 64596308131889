import React from "react";

export default function DropdownInput({
  className,
  placeholder,
  options,
  value,
  onChange,
}) {
  return (
    <div className="w-full z-20">
      <select
        value={value}
        onChange={onChange}
        className={[className, "w-full py-3 px-4 text-black"].join(" ")}
        style={{
          border: "0.62px solid #E0E0E1",
          borderRadius: "24px",
          color: value ? "black" : "gray",
        }}
        required
      >
        <option value="" disabled hidden>
          {placeholder}
        </option>
        {options?.map((item, index) => (
          <option key={index} value={item}>
            {item}
          </option>
        ))}
      </select>
    </div>
  );
}
