import { atom } from "recoil";

const localStorageEffect =
  (key) =>
  ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue, _, isReset) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

export const studentFormDataState = atom({
  key: "studentFormDataState",
  default: {
    formCode: "",
    studentName: "",
    topic: "",
    photo: null,
    photoRedirect: null,
    passportNumber: "",
    passportExpiredDate: "",
    passportFile: null,
    passportRedirect: null,
    address: "",
    allergies: "",
    medicalCondition: "",
    shirtSize: "",
    notes: "",
  },
  effects_UNSTABLE: [localStorageEffect("studentFormDataState")],
});
